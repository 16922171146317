import { classMerge } from '@components/utilities/classMerge'
import type { Gradients } from '@lib/colors/colors'

type GradientFadeProps = {
  backgroundGradient: Gradients
  className?: String
  fade?: boolean
  grainy?: boolean
  height: number
}

export const verticalFadeRule =
  'bg-gradient-to-b from-[rgba(255,255,255,0)] via-[rgba(255,255,255,0.8)] to-[rgba(255,255,255,1)]'

export const GradientFade = ({
  backgroundGradient,
  className,
  fade = true,
  grainy = false,
  height,
}: GradientFadeProps) => (
  <div
    className={classMerge(
      'absolute left-0 top-0 -z-10 w-full',
      {
        [`bg-${backgroundGradient}`]: backgroundGradient && !grainy,
        [`bg-${backgroundGradient}-grainy`]: backgroundGradient && grainy,
      },
      className,
    )}
    style={{ height: `${height}px` }}
  >
    <div className='relative size-full'>
      <div
        className={classMerge(
          'absolute bottom-0 -mt-64 h-64 w-full',
          fade && verticalFadeRule,
        )}
      />
    </div>
  </div>
)
