import TrialSearch from '@components/app/TrialSearch'
import { AnimatedDiv } from '@components/primitives/AnimatedContainer/AnimatedDiv'
import Button from '@components/primitives/Button'
import { Icon } from '@components/primitives/Icon'
import Link from '@components/primitives/Link'
import { AnimatedText } from '@components/primitives/Text/AnimatedText'
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch'
import animationPresets from '@lib/animation/presets'
import Routes from '@lib/routes'
import { TrackingSources } from '@lib/tracking/TrackingSources'

interface HomeHeroProps {
  heading: string
  subheading: string
}

/** Provide a large hero section, h1 + h2, for homepage. */
export function HomeHero({ heading, subheading }: HomeHeroProps) {
  return (
    <div className='grid grid-cols-1 gap-4'>
      <AnimatedText
        animationOptions={{
          ...animationPresets.fadeIn({
            duration: 800,
          }),
          translateY: [-6, 0],
        }}
        className='mx-auto mt-2 max-w-4xl text-center tracking-tight opacity-0 md:mt-6 lg:mt-16'
        element='h1'
        id='homepage-heading'
        styleName='hero'
        value={heading}
      />
      <AnimatedText
        animationOptions={{
          ...animationPresets.fadeIn({
            delay: 600,
            duration: 600,
          }),
          translateY: [-3, 0],
        }}
        className='mb-4 mt-0 text-center font-semibold tracking-tight opacity-0 lg:mb-8'
        element='h2'
        id='homepage-subheading'
        styleName='h2'
        value={subheading}
      />
      <AnimatedDiv
        animationOptions={animationPresets.fadeIn({
          delay: 1000,
          duration: 400,
        })}
        className='mx-auto mb-2 grid w-full grid-cols-1 gap-4 opacity-0'
        id='homepage-search'
      >
        <div className='hidden lg:block'>
          <TrialSearch
            autoFocus={true}
            includeResults={false}
            sticky={false}
            trackingSource={TrackingSources.HOMEPAGE}
          />
        </div>
        <Link
          className='flex w-full justify-center lg:hidden'
          href={Routes.search}
          rel={'nofollow'}
        >
          <Button
            className='w-full rounded-full p-4'
            color={'warm'}
            value={
              <span>
                <Icon className='mr-2' icon={faSearch} />
                Search
              </span>
            }
          ></Button>
        </Link>
      </AnimatedDiv>
    </div>
  )
}
